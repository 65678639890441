<template>
  <div class="className">
    <h3>Hello From YT!</h3>
    <section v-if="videoSrc">
      <h4>{{ currentVideo }} {{ qualityLabel }}</h4>
      <ul>
        <li><button @click="loadAudio(videoId, 128)">128kbit</button></li>
        <li><button @click="loadAudio(videoId, 256)">256kbit</button></li>
      </ul>
    </section>
    <input v-on:keyup.enter="searchAPI()" v-model.trim="params.q" placeholder="edit me" />
    <button @click="searchAPI()">search</button>
    <section v-if="videoSrc" id="videoSrcSec">
      <video controls autoplay loop :src="videoSrc" onloadstart="this.volume=0.5"></video>
    </section>
    <section v-if="videoSrc">
      <ul>
        <li>{{ viewCount }}</li>
        <li>{{ dateAdded }}</li>
      </ul>
      <span v-html="description.replace(/(?:\r\n|\r|\n)/g, '<br>')"></span>
    </section>

    <ul id="search-list-result">
      <li v-for="item in info.data.items" :key="item.etag">
        <div v-if="item.id.videoId && !item.id.channelId">
          <div :style="{ 'background-image': 'url(' + item.snippet.thumbnails.high.url + ')' }" class="thumbnail"
            @click="loadVideo(item.id.videoId)"></div>

          <span v-html="item.snippet.title"></span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {

  data: () => ({
    info: {
      data: {
        items: null,
      },
    },
    params: {
      part: "snippet",
      maxResults: 99,
      q: "marvel",
      key: process.env.VUE_APP_API,
    },
    videoSrc: null,
    currentVideo: null,
    videoId: null,
    qualityLabel: null,
    dateAdded: null,
    description: null,
    viewCount: null
  }),
  methods: {
    searchAPI() {
      localStorage.q = this.params.q;
      const qs = new URLSearchParams(this.params).toString();
      this.$http
        .get(
          "https://www.googleapis.com/youtube/v3/search?" + qs + "&type=video"
        )
        .then((response) => (this.info = response))
        .catch((error) => console.dir(error.response.data.error.message));
    },
    loadVideo(videoId) {
      //console.log(this.$route.path.slice(1))
      //console.log(videoId)

      if (this.$route.path.slice(1) != videoId) {
        this.$router.push(videoId)
      }

      if (videoId) {
        let options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        
        this.$http
          .get("https://yt-vue-server.vercel.app/video/" + videoId)
          //.get("https://yt-vue-server.vercel.app/video/" + videoId)
          //.get("http://localhost:3000/video/" + videoId)
          .then(
            (response) => (
              (console.log(response)),
              
              //(this.res = response.data.formats.filter(item => item.hasAudio === false)),
              (this.res = response.data.formats.filter(item => item.hasAudio === item.hasVideo)),
              (this.videoSrc = this.res[0].url),
              (this.currentVideo = response.data.videoDetails.title),
              (this.qualityLabel = this.res[0].qualityLabel),
              (this.dateAdded = new Date(response.data.videoDetails.publishDate).toLocaleString('HU-hu', options)),
              (this.viewCount = Number(response.data.videoDetails.viewCount)),
              (this.description = response.data.videoDetails.description),
              (this.videoId = videoId)
            ),
            window.scrollTo({ top: 0, behavior: "smooth" })
          )
          //.then(() => console.dir(this.videoSrc))
          .catch((error) => console.dir(error));
      }
    },
    loadAudio(videoId, bitrate) {
      if (videoId) {
        window
          .open("https://yt-server-render.onrender.com/audio/" + videoId + "/" + bitrate, "_blank")
          //.open("https://yt-vue-server.vercel.app/audio/" + videoId + "/" + bitrate, "_blank")
          //.open("http://s:3000/audio/" + videoId + "/" + bitrate, "_blank")
          .focus();
      }
    },
    handleScroll() {
      console.log(this.scrollEl.scrollTop)
    },
  },
  mounted() {
    if (localStorage.q) {
      this.params.q = localStorage.q;
    }

    if (this.$route.path && this.$route.path !== '/') {
      this.loadVideo(this.$route.path.slice(1))
    }
    document.querySelector('.className').addEventListener('scroll', this.handleScroll);
  },
  watch: {
    $route(to) {
      this.loadVideo(to.path.slice(1))
    }
  }
};
</script>

<style scoped>
#videoSrcSec {
  position: sticky;
  top: 0;
  border-radius: .5rem;
}

input {
  margin: 0px 5px 20px;
  padding: 5px;
}

.thumbnail {
  width: 100%;
  cursor: pointer;
  background-position: center;
  border-radius: .5rem;
  background-size: cover;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  aspect-ratio: 16/9 auto;
  margin-bottom: 1rem;
}

button {
  border-radius: .5rem;
  padding: .5rem 1rem;
  border: 1px white solid;
  cursor: pointer;
}

iframe {
  display: block;
  margin: 0 auto;
}

span {
  display: block;
}

ul {
  margin: 30px 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

ul li {
  list-style: none;
  width: calc(25% - 7.5px);
  height: auto;
}

[style*="--aspect-ratio"]> :first-child {
  width: 100%;
  border: 1px darkslategrey solid;
}

video {
  width: 50%;
  max-height: 100%;
  border-radius: .5rem;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  margin-top: .5rem;
}

@media screen and (max-width: 780px) {
  ul li {
    width: calc(50% - 15px);
    padding-right: 20px;
  }

  ul li:nth-child(2n + 0) {
    padding-right: 0;
  }

  video {
    width: 100%;
  }
}
</style>